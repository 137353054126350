import "./Footer.scss";
import React from "react";

export const Footer = () => {
  return (
    <footer>
      <div className="footer-inner">
        <p>© {new Date().getFullYear()} AlbaPro. All rights reserved</p>
      </div>
    </footer>
  );
};
