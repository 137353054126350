import "../styles/scss/ImageViewer.scss";
import React, { useState, useCallback, useEffect } from "react";
import { useSwipeable } from "react-swipeable";
import { Spinner } from "./Spinner";

export const ImageViewer = ({
  currentImage: propCurrentImage,
  photos = [],
  onClose,
}) => {
  const [{ loading, currentImage }, setState] = useState({
    currentImage: propCurrentImage,
    loading: false,
  });

  const photosLength = photos.length;

  const onImageLoad = useCallback(() => {
    setState((currentState) => {
      return {
        ...currentState,
        loading: false,
      };
    });
  }, [setState]);

  const prevImage = useCallback(() => {
    setState(({ currentImage }) => {
      return {
        currentImage: currentImage > 0 ? currentImage - 1 : photosLength - 1,
        loading: true,
      };
    });
  }, [setState, photosLength]);

  const nextImage = useCallback(() => {
    setState(({ currentImage }) => {
      return {
        currentImage: currentImage < photosLength - 1 ? currentImage + 1 : 0,
        loading: true,
      };
    });
  }, [setState, photosLength]);

  const onCancel = useCallback(() => {
    onClose();
  }, [onClose]);

  const onKeyDown = useCallback(
    ({ keyCode }) => {
      if (keyCode === 39) {
        nextImage();
      } else if (keyCode === 37) {
        prevImage();
      } else if (keyCode === 27) {
        onCancel();
      }
    },
    [prevImage, nextImage, onCancel]
  );

  useEffect(() => {
    window.addEventListener("keydown", onKeyDown, true);

    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
  }, [onKeyDown]);

  const handlers = useSwipeable({
    onSwipedLeft: prevImage,
    onSwipedRight: nextImage,
    trackMouse: true,
  });

  return (
    <div id="imageViewer" className="image-viewer">
      <div className="image-viewer-inner">
        <button className="close" onClick={onCancel}>
          <span />
          <span />
        </button>
        <button className="nav prev" onClick={prevImage} />
        <button className="nav next" onClick={nextImage} />

        <div {...handlers}>
          <div className="image-viewer-content">
            <div className="image-viewer-container">
              {loading && (
                <div className="spinner-holder">
                  <Spinner size="small" />
                </div>
              )}
              <img
                className={loading ? "hide-image" : ""}
                src={photos[currentImage].large}
                onLoad={onImageLoad}
                key={currentImage}
                alt={currentImage}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
