import React from "react";
import "../styles/scss/PlaceholderCards.scss";

export const VideoPlaceholder = ({ amount = 6 }) => {
  const placeholders = [];
  for (let i = 0; i < amount; i++) {
    placeholders.push(<div className="video-placeholder" key={i} />);
  }
  return placeholders;
};

export const AlbumPlaceholder = ({ amount = 6 }) => {
  const placeholders = [];
  for (let i = 0; i < amount; i++) {
    placeholders.push(<div className="album-placeholder" key={i} />);
  }
  return placeholders;
};
