import "./index.scss";
import React, { Fragment, useRef } from "react";
import { useVideosQuery } from "../../queries/useVideosQuery";
import { VideoCard, VideoPlaceholder } from "../../components";
import { useInfiniteScroll, useWindowTitle } from "../../hooks";

export const Videography = () => {
  const { data, isFetching, fetchNextPage } = useVideosQuery();
  const ref = useRef();

  useWindowTitle("Videography");
  useInfiniteScroll(ref, () => {
    if (!isFetching) {
      const pageCount = data?.pages.length;

      if (pageCount === 1) {
        fetchNextPage({ pageParam: 18 });
      } else {
        fetchNextPage({ pageParam: pageCount * 2 * 9 });
      }
    }
  });

  const pages = data?.pages || [];

  return (
    <div className="cinematography">
      {pages.map((page, idx) => (
        <Fragment key={idx}>
          {page?.data?.allVideos.nodes.map((video, idx) => (
            <VideoCard video={video} key={idx} />
          ))}
        </Fragment>
      ))}
      {isFetching && <VideoPlaceholder amount={9} />}
      <div ref={ref} style={{ width: "1px", height: "1px" }} />
    </div>
  );
};
