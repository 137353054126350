import { useQuery } from "@tanstack/react-query";
import { fetch } from "../utilities";

const query = `
  query video($id: Int!) {
    videoById(id: $id) {
      id
      name
      plays
      description
      externalLink
      releasedAt
      link
    }
  }
`;

export const useVideoQuery = (id) => {
  return useQuery({
    queryKey: ["video", id],
    queryFn: () => fetch(query, { id }),
  });
};
