import "./AlbumCard.scss";
import React from "react";
import { Link } from "react-router-dom";
import { MdLock } from "react-icons/md";

export const AlbumCard = ({ album }) => {
  return (
    <Link
      to={`/photography/${album.id}`}
      className="album-card"
      style={{ backgroundImage: `url('${album.coverBySize.medium}')` }}
    >
      <div className="album-card-meta">
        <h5>{album.name}</h5>
        {album.protected && <MdLock color="white" />}
      </div>
    </Link>
  );
};
