import "./RecentVideos.scss";
import React from "react";
import { useRecentVideosQuery } from "../../queries";
import { VideoCard, VideoPlaceholder } from "../../components";
import { Link } from "react-router-dom";
import { HiOutlineArrowRight } from "react-icons/hi";

export const RecentVideos = () => {
  const { data, isLoading } = useRecentVideosQuery();

  const videos = data?.data?.allVideos?.nodes || [];

  return (
    <div className="recent-videos">
      <div className="recent-videos-header">
        <h2>Videography</h2>
        <p>
          Showcasing our talented photographers and videographers' artistry
          capturing special moments from weddings, baby showers, engagements,
          and more. Relive the magic and get inspired for your next event.
        </p>
      </div>
      <div className="recent-videos-grid">
        {videos.map((video, idx) => {
          return <VideoCard video={video} key={idx} />;
        })}
        {isLoading && <VideoPlaceholder amount={8} />}
      </div>
      <div className="recent-videos-links">
        <Link to="/videography">
          View Videography <HiOutlineArrowRight />
        </Link>
      </div>
    </div>
  );
};
