import { useQuery } from "@tanstack/react-query";
import { fetch } from "../utilities";

const query = `
    query getAlbum($id: Int!) {
        albumById(id: $id) {
        id
        name
        protected
        photosBySize {
            large
            medium
            small
        }
        }
    }
`;

export const useAlbumQuery = (id, password) => {
  const headers = {
    "X-Platform-Source": "web",
  };

  if (password) {
    headers["X-Album-Password"] = password;
  }

  return useQuery({
    queryKey: ["album", id, password],
    queryFn: () => fetch(query, { id }, headers),
  });
};
