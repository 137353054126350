import "./index.scss";
import React from "react";
import { RecentAlbums } from "./RecentAlbums";
import { RecentVideos } from "./RecentVideos";
import { BookingBanner } from "./BookingBanner";
import { useWindowTitle } from "../../hooks";
import { CoverImage } from "../../components";

export const Home = () => {
  useWindowTitle();

  return (
    <div className="home">
      <CoverImage />
      <RecentAlbums />
      <RecentVideos />
      <BookingBanner />
    </div>
  );
};
