import "./index.scss";
import React, { useRef, useState, useCallback } from "react";
import { useAlbumQuery } from "../../queries";
import { useParams } from "react-router-dom";
import { ImageViewer } from "../../components";
import { Spinner } from "../../components";
import { MdLock } from "react-icons/md";
import { useWindowTitle } from "../../hooks";

export const Album = () => {
  const { id } = useParams();
  const [password, setPassword] = useState(null);
  const [currentImage, setCurrentImage] = useState();
  const passwordRef = useRef(null);
  const { data, isLoading } = useAlbumQuery(parseInt(id), password);
  const isAlbumLocked = data?.data?.albumById === null;

  useWindowTitle(data?.data?.albumById?.name);

  const onUnlockAlbum = useCallback(
    (event) => {
      event.preventDefault();
      setPassword(passwordRef.current.value);
    },
    [passwordRef]
  );

  if (isLoading) {
    return (
      <div className="album-loading">
        <Spinner size="small" />
      </div>
    );
  }

  if (isAlbumLocked) {
    const isError = Array.isArray(data?.errors) && password;
    return (
      <div className="album-password">
        <MdLock size="18px" color="white" />
        <h4>Enter password to continue</h4>
        <form onSubmit={onUnlockAlbum}>
          <input
            ref={passwordRef}
            type="password"
            placeholder="Password"
            required
          />
          <button>Continue</button>
        </form>
        {isError && <h5>Incorrect password please try again.</h5>}
      </div>
    );
  }

  const photos = data?.data?.albumById?.photosBySize || [];

  return (
    <>
      <div className="album-grid">
        {photos.map((photo, idx) => {
          return (
            <div
              className="album-grid-photo"
              style={{ backgroundImage: `url('${photo.medium}')` }}
              onClick={() => setCurrentImage(idx)}
              key={idx}
            />
          );
        })}
      </div>
      {typeof currentImage === "number" && (
        <ImageViewer
          photos={photos}
          currentImage={currentImage}
          onClose={setCurrentImage}
        />
      )}
    </>
  );
};
