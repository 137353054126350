import "./index.scss";
import React from "react";
import { useParams } from "react-router-dom";
import ReactPlayer from "react-player/vimeo";
import { useVideoQuery } from "../../queries";
import { Spinner } from "../../components";
import { MdPlayArrow } from "react-icons/md";
import { FaClock } from "react-icons/fa";
import { formatDistance } from "date-fns";
import { useWindowTitle } from "../../hooks";

export const Video = () => {
  const { id } = useParams();
  const { data, isLoading } = useVideoQuery(parseInt(id));

  useWindowTitle(data?.data?.videoById?.name);

  if (isLoading) {
    return (
      <div className="video-loading">
        <Spinner size="small" />
      </div>
    );
  }

  const video = data?.data?.videoById;

  return (
    <div className="video">
      <div className="video-player-container">
        <ReactPlayer
          url={video.externalLink}
          playing
          width="100%"
          height="100%"
          controls
        />
      </div>
      <div className="video-player-details">
        <div className="video-name-plays">
          <h3>{video.name}</h3>
          <div className="video-plays">
            <MdPlayArrow color="#929292" />
            <h5>{video.plays} views</h5>
          </div>
        </div>
        <div className="video-timestamp">
          <FaClock color="#929292" />
          <h5>
            {formatDistance(new Date(video.releasedAt), new Date(), {
              addSuffix: true,
            })}
          </h5>
        </div>
      </div>
    </div>
  );
};
