import "./RecentAlbums.scss";
import React from "react";
import { useRecentAlbumsQuery } from "../../queries";
import { AlbumCard, AlbumPlaceholder } from "../../components";
import { Link } from "react-router-dom";
import { HiOutlineArrowRight } from "react-icons/hi";

export const RecentAlbums = () => {
  const { data, isLoading } = useRecentAlbumsQuery();

  const albums = data?.data?.allAlbums?.nodes || [];

  return (
    <div className="recent-albums">
      <div className="recent-albums-header">
        <h2>Photography</h2>
        <p>
          Featuring a collection of stunning images from our talented
          photographers. From captivating portraits to memorable events, get
          inspired by our latest and greatest work.
        </p>
      </div>
      <div className="recent-albums-grid">
        {albums.map((album, idx) => {
          return <AlbumCard album={album} key={idx} />;
        })}
        {isLoading && <AlbumPlaceholder amount={4} />}
      </div>
      <div className="recent-albums-links">
        <Link to="/photography">
          View Photography <HiOutlineArrowRight />
        </Link>
      </div>
    </div>
  );
};
