import React from "react";
import { Link } from "react-router-dom";

export const HeaderLinks = ({ closeMenu }) => {
  return (
    <>
      <Link to="/about" onClick={closeMenu}>
        About
      </Link>
      <Link to="/photography" onClick={closeMenu}>
        Photography
      </Link>
      <Link to="/videography" onClick={closeMenu}>
        Videography
      </Link>
      <Link to="mailto:albaprony@gmail.com" onClick={closeMenu}>
        Contact
      </Link>
    </>
  );
};
