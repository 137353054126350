import { useInfiniteQuery } from "@tanstack/react-query";
import { fetch } from "../utilities";

const query = `
  query videos($first: Int!, $offset: Int!) {
    allVideos(first: $first, offset: $offset) {
      nodes {
        name
        id
        coverBySize {
          small
          medium
          large
        }
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`;

export const useVideosQuery = () => {
  return useInfiniteQuery({
    queryKey: ["videos"],
    queryFn: ({ pageParam: offset = 9 }) => {
      return fetch(query, { first: 9, offset });
    },
  });
};
