import { useInfiniteQuery } from "@tanstack/react-query";
import { fetch } from "../utilities";

export const query = `
  query albums($after: Cursor) {
    allAlbums(
      first: 12
      after: $after
      condition: { published: true }
      orderBy: ID_DESC
    ) {
      nodes {
        id
        name
        protected
        coverBySize {
          large
          small
          medium
        }
      }
      pageInfo {
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
`;

export const useAlbumsQuery = () => {
  return useInfiniteQuery({
    queryKey: ["albums"],
    queryFn: ({ pageParam: after }) => fetch(query, { after }),
    getNextPageParam: (data) => data?.data?.allAlbums.pageInfo.endCursor,
    getPreviousPageParam: (data) => data?.data?.allAlbums.pageInfo.startCursor,
  });
};
