const path = "https://v1.albapro.com/data/graphql";
const defaultHeaders = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

export const fetch = async (query, variables = {}, headers = {}) => {
  const response = await window.fetch(path, {
    method: "POST",
    headers: {
      ...defaultHeaders,
      ...headers,
    },
    body: JSON.stringify({
      query,
      variables,
    }),
    credentials: "same-origin",
  });

  if (!response.ok) {
    throw Error("Request failed");
  }

  return await response.json();
};
