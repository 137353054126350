import React from "react";
import { Route, Routes } from "react-router-dom";
import {
  About,
  Album,
  Home,
  Photography,
  Pricing,
  Video,
  Videography,
} from "./routes";

export const Router = () => {
  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/about" element={<About />} />
      <Route exact path="/pricing" element={<Pricing />} />
      <Route exact path="/photography" element={<Photography />} />
      <Route exact path="/photography/:id" element={<Album />} />
      <Route exact path="/videography" element={<Videography />} />
      <Route exact path="/videography/:id" element={<Video />} />
    </Routes>
  );
};
