import { useQuery } from "@tanstack/react-query";
import { fetch } from "../utilities";

const query = `
    query videos {
        allVideos(first: 8) {
            nodes {
                id
                name
                coverBySize {
                    small
                    medium
                    large
                }
            }
        }
    }
`;

export const useRecentVideosQuery = () => {
  return useQuery({
    queryKey: ["recentVideos"],
    queryFn: () => fetch(query),
  });
};
