import "./index.scss";
import React, { Fragment, useRef } from "react";
import { useAlbumsQuery } from "../../queries";
import { AlbumCard, AlbumPlaceholder } from "../../components";
import { useInfiniteScroll, useWindowTitle } from "../../hooks";

export const Photography = () => {
  const { data, isFetching, fetchNextPage, hasNextPage } = useAlbumsQuery();
  const ref = useRef();

  useWindowTitle("Photography");
  useInfiniteScroll(ref, () => {
    if (hasNextPage && !isFetching) {
      fetchNextPage();
    }
  });

  const pages = data?.pages || [];

  return (
    <div className="photojournalism">
      {pages.map((page, idx) => (
        <Fragment key={idx}>
          {page.data.allAlbums.nodes.map((album, idx) => (
            <AlbumCard album={album} key={idx} />
          ))}
        </Fragment>
      ))}
      {isFetching && <AlbumPlaceholder amount={9} />}
      <div ref={ref} style={{ width: "1px", height: "1px" }} />
    </div>
  );
};
