import "./BookingBanner.scss";
import React from "react";
import { Link } from "react-router-dom";
import { GiBigDiamondRing } from "react-icons/gi";
import { HiOutlineArrowRight } from "react-icons/hi";

export const BookingBanner = () => (
  <div className="booking-banner">
    <div className="booking-banner-inner">
      <GiBigDiamondRing className="diamond-ring" color="#b9f2ff" />
      <h2>Your Wedding, A Time To Remember...</h2>
      <div className="booking-banner-description">
        <p>
          We at AlbaPro are committed to bringing excellence and professionalism
          to your wedding day, while creating a stress-free and fun environment.
          But we also strive to personalize our approach, and tailor our
          services to your wedding, because each wedding is as unique and
          different as is every bride. The exhilaration of the first twirl in
          the wedding dress, the joy of seeing your other half for the first
          time, the spontaneous first hand-holding, and the nervous first dance.
          We are eager to capture it all in the best light possible, because
          your wedding day is the beginning of the rest of your lives as one.
          Your wedding, picture-perfect.
        </p>
      </div>
      <Link to="mailto:albaprony@gmail.com">
        Check availability <HiOutlineArrowRight />
      </Link>
    </div>
  </div>
);
