import "./CoverImage.scss";
import React from "react";
import { useLocation } from "react-router-dom";

export const CoverImage = () => {
  const { pathname } = useLocation();
  const isHomePage = pathname === "/";

  if (!isHomePage) {
    return null;
  }

  return <div className="cover" />;
};
