import "./styles/scss/app.scss";
import React, { useState, useCallback } from "react";
import { BrowserRouter } from "react-router-dom";
import { Router } from "./Router";
import { Footer, HeaderLinks } from "./components";
import { Link } from "react-router-dom";
import { QueryClientProvider } from "./providers";
import Logo from "./images/logo.png";

export const Root = () => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const toggleIsMenuVisible = useCallback(() => {
    setIsMenuVisible((visible) => !visible);
  }, [setIsMenuVisible]);

  return (
    <BrowserRouter>
      <QueryClientProvider>
        <div className={`side-menu ${isMenuVisible ? "visible" : "hidden"}`}>
          <div className="side-menu-inner">
            <div className="side-menu-body">
              <HeaderLinks closeMenu={toggleIsMenuVisible} />
            </div>
          </div>
        </div>
        <header className="desktop-menu">
          <div className="header-inner">
            <div className="header-top">
              <Link to="/">
                <img src={Logo} alt="AlbaPro" />
              </Link>
            </div>
            <div className="header-body">
              <HeaderLinks />
            </div>
          </div>
        </header>
        <div className={`mobile-menu ${isMenuVisible ? "menu-active" : ""}`}>
          <div className="col">
            <Link to="/">
              <img src={Logo} alt="AlbaPro" />
            </Link>
          </div>
          <div className="col">
            <button
              className={isMenuVisible ? "opened" : "closed"}
              onClick={toggleIsMenuVisible}
            ></button>
          </div>
        </div>
        <main className={`${isMenuVisible ? "menu-active" : ""}`}>
          <Router />
        </main>
        <Footer />
      </QueryClientProvider>
    </BrowserRouter>
  );
};
