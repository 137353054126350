import React from "react";
import { Link } from "react-router-dom";
import "../styles/scss/VideoCard.scss";

export const VideoCard = ({ video = {} }) => (
  <Link
    to={`/videography/${video.id}`}
    style={{ backgroundImage: `url(${video.coverBySize.medium})` }}
    className="video-card"
  >
    <div className="video-card-meta">
      <h5>{video.name}</h5>
    </div>
  </Link>
);
