import { useEffect } from "react";

export const useInfiniteScroll = (ref, cb, options = { root: null }) => {
  useEffect(() => {
    const observer = new IntersectionObserver(([target]) => {
      if (target.isIntersecting) {
        cb();
      }
    }, options);

    if (ref?.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [ref, cb, options]);
};
