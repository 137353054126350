import "./index.scss";
import React from "react";
import { useWindowTitle } from "../../hooks";

export const About = () => {
  useWindowTitle("About");
  return (
    <div className="about">
      <div className="about-inner">
        <p>
          AlbaPro is a video and photography studio specializing in weddings and
          corporate events.
        </p>
        <p>
          Its founder, Amir Sukalic, is a winner of five prestigious Emmy
          Awards, and one Telly award for his work in television. His signature
          "short-film" editing style is a favorite of the contemporary bride. He
          has produced events in New York, Chicago, Detroit, Boston, Las Vegas,
          Los Angeles, Miami and internationally in the Caribbean, Montenegro,
          and Italy.
        </p>
        <p>
          Amir is currently the Senior Director of Photography for NYC-Media. He
          was a director of photography for the "Eat Out New York" with Kelly
          Choi, "That's so New York", and "Secrets of New York". He also serves
          as the director of programing for Alba Life TV, a non-profit Albanian
          Language television station broadcast on Staten Island and Brooklyn's
          community channels.
        </p>
        <p>
          Amir is an avid football (soccer) player and an AS Roma fan; he
          resides on Staten Island, NY with his wife, and AlbaPro lead editor,
          Lumi, son Aaron and daughter Aurora. He is fluent in Albanian and
          Montenegrin.
        </p>
      </div>
    </div>
  );
};
