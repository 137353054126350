import { useQuery } from "@tanstack/react-query";
import { fetch } from "../utilities";

const query = `
    query albums {
        allAlbums(
            first: 4
            condition: { published: true }
            orderBy: ID_DESC
        ) {
            nodes {
                id
                name
                protected
                coverBySize {
                    large
                    small
                    medium
                }
            }
        }
    }
`;

export const useRecentAlbumsQuery = () => {
  return useQuery({
    queryKey: ["recentAlbums"],
    queryFn: () => fetch(query),
  });
};
